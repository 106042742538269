import debounce from 'debounce';

function handleGtagEvent(element) {
  return () => {
    const elementData = element.dataset;
    if (
      !(
        elementData &&
        elementData.gtagAction &&
        elementData.gtagCategory &&
        elementData.gtagLabel
      )
    ) {
      return;
    }
    gtag({
      event: elementData.gtagAction,
      event_category: elementData.gtagCategory,
      event_label: elementData.gtagLabel,
    });
  };
}

function gtagListeners() {
  // Capture CDS Select Animation
  const cdsSelect = $('#cdsSelect');
  cdsSelect.on('change', () => {
    gtag({
      event: 'Select',
      event_category: 'Home Page',
      event_label: `Animation CDS - ${cdsSelect.val()}`,
    });
  });

  document.querySelectorAll('[data-gtag-event]').forEach((element) => {
    const eventName = element.dataset.gtagEvent;
    const fn =
      eventName === 'input' || eventName === 'mouseover'
        ? debounce(handleGtagEvent(element), 800)
        : handleGtagEvent(element);
    element.addEventListener(eventName, fn);
  });
}

export default function googleTagsInit() {
  gtagListeners();
}
