export default function headerFunctionsInit() {
  // NavDropdown
  const $linkContainers = $('.nav .has-dropdown, .nav .has-nav-button');
  $linkContainers.on('mouseover', function () {
    $(this).addClass('is-hover');
  });

  $linkContainers.on('mouseleave', function () {
    $(this).removeClass('is-hover');
  });

  //mobile Menu
  const $menu = $('.menu');
  const $nav = $('.header__nav');

  $menu.on('click', function (event) {
    event.preventDefault();
    $(this).toggleClass('is-active');
    $nav.toggleClass('is-active');
  });
}
